import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-white-large.svg' 
import instagram from '../img/social/instagram-light.svg'
import twitter from '../img/social/twitter-light.svg'
import youtube from '../img/social/youtube-light.svg'

const Footer = class extends React.Component {
  constructor(props){
    super(props);
    this.state = {emailValue: ''}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (e) => {
    this.setState({emailValue: e.target.value})
  }
  
  render() {
    return (
      <footer className="footer has-background-black has-text-white">
        <img
          src={logo}
          alt="Harina Logo"
          className="big-background-logo"
        />
        <div className="content has-text-centered footer-main-content">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-two-thirds">
                <h2>Never miss another Harina story. Get tips, recipes, updates, and more straight to your inbox</h2>
                <form action="https://theharina.us18.list-manage.com/subscribe/post?u=0e32f11efd3ce2a39dfa9e9c3&amp;id=5a0aecd2ec" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <div className="robocop-pixel" aria-hidden="true">
                    <input type="text" name="b_0e32f11efd3ce2a39dfa9e9c3_5a0aecd2ec" tabindex="-1" value="" />
                  </div>
                  <div className="email-inline-container">
                    <input
                      type="email" 
                      value={this.state.emailValue} 
                      name="EMAIL" 
                      className="email input email-input-inline" 
                      id="mce-EMAIL" 
                      placeholder="Enter email address"
                      onChange = {this.handleChange}
                      required 
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button email-submit-footer" 
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-half social">
                <a title="twitter" href="https://twitter.com/theharina">
                  <img
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                  />
                </a>
                <a title="youtube" href="https://youtube.com">
                  <img
                    src={youtube}
                    alt="youtube"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
