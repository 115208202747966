import React from 'react'

const Modal = class extends React.Component {
  constructor(props){
    super(props);
    this.state = {emailValue: ''}
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({emailValue: e.target.value})
  }

  
  handleModalClose = (e) => {
    document.querySelector(".modal").classList.remove("is-active")
    document.querySelector("html").classList.remove("is-clipped")
  }

  render(){
    return(
      <div className="modal">
        <div className="modal-background" onClick={this.handleModalClose}></div>
        <div className="modal-content newsletter-modal-content">
          <img alt="Lady pressing tortillas" src="/img/newsletter-hero.jpg" />
          <form action="https://theharina.us18.list-manage.com/subscribe/post?u=0e32f11efd3ce2a39dfa9e9c3&amp;id=5a0aecd2ec" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <input 
                type="email" 
                value={this.state.emailValue}
                name="EMAIL" 
                className="email newsletter-email-input js-newsletter-email-input" 
                id="mce-EMAIL" 
                placeholder="Enter your email address"
                onChange={this.handleChange}
                required 
              />
              <div className="robocop-pixel" aria-hidden="true">
                <input type="text" name="b_0e32f11efd3ce2a39dfa9e9c3_5a0aecd2ec" tabindex="-1" value="" />
              </div>
              <div className="clear">
                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button newsletter-modal-submit" />
              </div>
            </div>
          </form>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.handleModalClose}></button>
      </div>
     )
  }
}

export default Modal